.About {
    box-sizing: border-box;
    min-height: 100vh;

    @media (max-width: 767px) {
        padding-top: 100px;
    }

    .container {
        display: flex;
        min-height: 100vh;
        flex-direction: column;
        justify-content: center;
        align-items: center;    
    }

    h1 {
        font-size: 30px;
        padding: 0;
        margin: 0;
        font-family: 'Fjalla One', sans-serif;
        text-transform: uppercase;
        
        @media (min-width: 768px) {
            font-size: 50px;
            letter-spacing: -2px;
        }
    }

    h2 {
        font-size: 16px;
        text-align: center;
        margin: 16px 0 50px;
        font-family: 'Quicksand', sans-serif;
        color: #aaa;
        font-weight: 100;  
        line-height: 35px;  
        
        @media (min-width: 768px) {
            font-size: 14px;
        }        
    } 
    
    .about-boxes {
        width: 1000px;
        max-width: 100%;
        justify-content: space-around;

        @media (min-width: 768px) {
            display: flex;
        }
    }

    .about-image {
        width: 116px;
        max-width: 100%;
        border-radius: 50%;
        margin: 0 auto 24px;
        border: 7px solid #fff;
        height: 116px;
    }
}