.SkillsTabs {
    display: flex;
    margin: 0 auto 40px;
    padding-top: 30px;    
    justify-content: space-around;

    ul {
        max-width: 100%;
        display: flex;
        padding: 0;
        flex: 1;
        justify-content: space-around;

        @media (min-width: 1000px) {
            max-width: 1000px;
        }
    }
}
