.FooterLink {
    margin: 0 10px;

    a {
        display: block;

        svg {
            width: 30px;
            transition: .5s filter;

            &:hover {
                filter: invert(1);
            }
        }
    }
}