.Header {
    width: 100%;
    min-height: 60px;
    background-color: #0e0e0e;
    position: fixed;
    border-bottom: 1px solid #161616;
    top: 0;
    box-shadow: 0 3px 13px #000;
    display: flex;
    z-index: 3;
}