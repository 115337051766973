.PortfolioItem {
    padding: 10px;
    flex: 1;
    justify-content: center;
    align-items: center;
    display: flex;
    min-width: 70px;
    
    img {
        width: 120px;
        max-width: 100%;
        -webkit-box-reflect: below 2px -webkit-gradient(linear, left top, left bottom, from(transparent), to(rgba(0,0,0,.07)));
        
        @media (min-width: 768px) {
            width: 70px;
        }

        &.embraer {
            width: 100px;
            filter: grayscale(1) invert(1) brightness(3);
        }

        &.guiche {
            // width: 70px;
        }

        &.focus {
            // width: 40px;
            filter: grayscale(1)
        }

        &.safra {
            // width: 60px;
            filter: grayscale(1) invert(1) brightness(3);
        }

        &.btg {
            filter: grayscale(1) invert(1) brightness(3);
        }

        &.poliedro {
            filter: grayscale(1) invert(1) brightness(3);
        }
        
        &.santander {
            width: 100px;
            margin-top: 6px;
        }        
    }
}