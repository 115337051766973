body {
  background-color: #191919;
  color: #fff;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;  
}

li {
  list-style: none;
}

li,
p,
a {
  font-family: 'Quicksand', sans-serif;
  font-weight: 100;
  font-size: 14px;
}

a {
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
}

.container {
  width: 100%;
  max-width: 100%;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  display: inherit;

  @media (min-width: 1300px) {
      width: 1300px;
      padding: 0;

      &.container-small {
        width: 1000px;
      }      
  }  
}

.flex {
  display: flex;
}

.btn {
    border: 1px solid rgba(255, 255, 255, .1);
    color: rgba(255, 255, 255, .8);
    line-height: 40px;
    text-decoration: none;
    font-family: 'Quicksand', sans-serif;
    font-size: 14px;
    text-align: center;
    justify-content: inherit;   
    transition: .9s color ease, .2s border-color ease;

    &:hover {
        color: #fff;
        border-color: #fff;
        text-decoration: none;
    }
}

.hide-tablet {
  @media (min-width: 768px) {
    display: none!important;
  }
}

.show-tablet {
  @media (max-width: 768px) {
    display: none!important;
  }
}