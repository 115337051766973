.MenuTrigger {
    display: none;

    @media (max-width: 5799px) {
      width: 30px;
      align-items: center;
      display: flex;
      
      $menuColor: #368c8b;
      $menuColorActive: #fff;
      
      .toggle {
        width: 33px;
        height: 17px;
        display: block;
        position: relative;
        -webkit-user-select: none;
        user-select: none;
      
        span {
          display: block;
          height: 2px;
          margin-bottom: 5px;
          position: relative;
          background: $menuColor;
          // border-radius: 3px;
          transform-origin: 4px 0px;
          transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                      opacity 0.55s ease;
      
          &:first-child {
            transform-origin: 0% 0%;
          }
          
          &:nth-last-child(2) {
            transform-origin: 0% 100%;
          } 
        }  
        
        input {
          display: block;
          width: 40px;
          height: 32px;
          position: absolute;
          top: -7px;
          left: -5px;
          cursor: pointer;
          opacity: 0;
          z-index: 2;
          -webkit-touch-callout: none;
          
          &:checked {
            ~ span {
              opacity: 1;
              transform: rotate(45deg) translate(-2px, -2px);
              background: $menuColorActive;
            
              &:nth-last-child(1) {        
                transform: rotate(-45deg) translate(-1px, -2px);
              }  
              
              &:nth-last-child(2) {
                opacity: 0;
                transform: scale(0.1, 0.1);
              } 
            } 
            
            + label {
              width: 100%;
              height: 100%;
              position: fixed;
              left: 0;
              top: 0;
            }
          }
        } 
      }
    }
}