.Portfolio {
    background-color: #0e0e0e;
    box-shadow: 0 -1px 20px #000;
    padding: 100px 0 0;
    
    @media (min-width: 768px) {
        min-height: 100vh;
    }

    h5 {
        display: flex;
        font-family: 'Quicksand', sans-serif;
        justify-content: center;
        font-size: 13px;
        margin: 20px 0;
        color: #888;        
    }
}