.PortfolioPersonalItem {
    flex: 1;
    background-color: #333;
    min-width: 140px;
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;

    .box-content {
        min-height: 100px;

        @media (min-width: 768px) {
            min-height: 150px;
        }
    }
    
    h3 {
        font-family: 'Caveat', cursive;
        font-size: 20px;
        color: #368c8b;
        margin: 0;
    }

    p {
        color: #888;
        // padding: 0 10px;
    }  
}