.Sidebar {
    width: 320px;    
    left: -320px;
    min-height: 100vh;
    position: fixed;
    top: 0;
    z-index: 3;
    background-color: rgba(0,0,0,.9);
    top: 60px;
    transition: .2s .1s left cubic-bezier(0.37,0.2,0.05,1.0);
    
    @media(min-width: 768px) {        
        background-color: rgba(0,0,0,.8);
    }

    &::before {
        width: 100%;
        height: 100%;
        position: fixed;
        top: 0;
        left: 0;
        background: rgba(0,0,0,.5);
        z-index: -1;        
        opacity: 0;
        pointer-events: none;
        content: "";
        transition: .2s opacity ease;
    }

    &.active {
        left: 0;
        transition: .2s left cubic-bezier(0.37,0.2,0.05,1.0);

        &::before {
            transition: .6s .1s opacity ease;
            opacity: 1;
        }
    }
}