.Footer {
    text-align: center;
    background-color: #368c8b;
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;

    ul {
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: center;
        min-height: 44px;
    }
}