.SkillsTab {
    list-style: none;
    cursor: pointer;
    padding: 2px 5px;
    text-align: center;
    font-family: 'Quicksand', sans-serif;
    font-size: 13px;
    font-weight: 100;
    line-height: 43px;
    color: #aaa;
    transition: .3 all ease;
    border: 1px solid;
    border-color: transparent transparent rgba(255, 255, 255, .05);    
    transition: border-color .4s, color .5s;
    user-select: none;

    @media (min-width: 768px) {
        font-size: 16px;
        padding: 2px 10px;
        min-width: 100px;
    }

    &:hover {
        color: #368c8b;
    }

    &.active {
        color: #368c8b;
        border: 1px solid #368c8b;
    }
}